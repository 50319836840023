code[class*="language-"],
pre[class*="language-"] {
   color: #ccc;
   background: none;
   font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
   text-align: left;
   white-space: pre;
   word-spacing: normal;
   word-break: normal;
   word-wrap: normal;
   line-height: 1.5;

   -moz-tab-size: 4;
   -o-tab-size: 4;
   tab-size: 4;

   -webkit-hyphens: none;
   -moz-hyphens: none;
   -ms-hyphens: none;
   hyphens: none;
}

.noUi-connects {
   background-color: #d2d6de;
   border-radius: 3px;
   overflow: hidden;
   z-index: 0;
   width: 100%;
   height: 100%;
   position: relative;
   z-index: 1;
}
.noUi-vertical .noUi-origin {
   left: 0 !important;
}

/* Code blocks */
pre[class*="language-"] {
   padding: 1em;
   margin: 0.5em 0;
   overflow: auto;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
   background: #2d2d2d;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
   padding: 0.1em;
   border-radius: 0.3em;
   white-space: normal;
}
#slider-toggle {
   height: 50px;
   margin: auto;
}
.examples {
   width: 75%;
   margin: auto auto 50px;
}

.examples h4 {
   text-align: center;
}

.examples button {
   margin: 20px;
}

.warning {
   width: 50%;
   background-color: #fff;
   color: #c0392b;
   text-align: center;
   margin: auto auto 20px;
}
#red,
#green,
#blue {
   margin: 10px;
   display: inline-block;
   height: 200px;
}

#colorpicker {
   height: 240px;
   width: 310px;
   margin: 0 auto;
   padding: 10px;
}

#result {
   margin: 60px 26px;
   height: 100px;
   width: 100px;
   display: inline-block;
   vertical-align: top;
   border: 1px solid #fff;
   box-shadow: 0 0 10px;
}

#red .noUi-connect {
   background: #c0392b;
}

#green .noUi-connect {
   background: #27ae60;
}

#blue .noUi-connect {
   background: #2980b9;
}
